<template>
  <div>
    <div v-for="(field, i) in conditionTypeFields.thresholds" :key="i">
      <div class="row">
        <div class="col-lg-3">
          <label class="mb-2">
            Threshold Metric
          </label>
          <div class="mb-2 dropdown-open-top">
            <ValidationProvider 
              rules="required"
              name="Affected Metrics" 
              v-slot="{ errors }"
            >
              <choices-single
                :options="
                  Object.values(conditionThresholdMetrics).map((c, i) => {
                    return { id: i, text: c };
                  })
                "
                v-model="field.metric"
                class="charge-unit-dropdown"
              >
                <option value="0">Select</option>
              </choices-single>
              <span class="validate-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-lg-3">
          <label class="mb-2">
            Less than / Greater than
          </label>
          <div class="mb-2 dropdown-open-top">
            <ValidationProvider
              rules="required"
              name="Direction"
              v-slot="{ errors }"
            >
              <choices-single
                :options="conditionDirection"
                v-model="field.direction"
                class="charge-unit-dropdown"
              >
                <option value="0">Select</option>
              </choices-single>
              <span class="validate-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-lg-3">
          <base-input
            type="number"
            label="Threshold"
            step="any"
            name="Threshold"
            v-model="field.value"
            class="mb-0"
            rules="required"
            v-on:keypress="positiveNumbersOnly"
          >
          </base-input>
        </div>
        <div class="col-lg-3">
          <label class="mb-2">
            Unit type
          </label>
          <div class="mb-2 dropdown-open-top">
            <ValidationProvider
              rules="required"
              name="Unit type"
              v-slot="{ errors }"
            >
              <choices-single
                :options="conditionUnitTypeList"
                v-model="field.unitType"
                class="charge-unit-dropdown"
              >
                <option value="0">Select</option>
              </choices-single>
              <span class="validate-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <i
          v-if="conditionTypeFields.thresholds.length > 1"
          class="fa fa-trash thresholds-delete"
          @click="removeThresholds(i)"
        >
        </i>
      </div>
    </div>

    <base-button @click="addThresholds">
      Add +
    </base-button>

    <div class="row mt-3">
        <div class="col-lg-3">
          <label class="mb-2">
            Affects
          </label>
          
          <div class="mb-2 dropdown-open-top">
            <ValidationProvider
              :rules="validationRules"
              name="Affects"
              v-slot="{ errors }"
            >
              <choices-single
                :options="conditionAffects"
                v-model="conditionTypeFields.affects.affects"
                class="charge-unit-dropdown"
                @input="event => changeAffects(event)"
              >
                <option value="0">Select</option>
              </choices-single>
              <span class="validate-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="conditionTypeFields.affects.affects === 'Price'" class="col-lg-3">
          <label class="mb-2">
            Pricing method
          </label>
          <div class="mb-2 dropdown-open-top">
            <ValidationProvider rules="required" name="Pricing method" v-slot="{ errors }">
              <choices-single
                :options="conditionPricingMethodList"
                v-model="conditionTypeFields.affects.pricingMethodType"
                class="charge-unit-dropdown"
              >
                <option value="0">Select</option>
              </choices-single>
              <span class="validate-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="conditionTypeFields.affects.affects === 'Price'" class="col-lg-3">
          <div class="form-group has-label mb-0">
            <label>Price</label>
            <div>
              <ValidationProvider
                rules="required"
                name="Price"
                v-slot="{ errors }"
              >
                <span class="data-type-input currency-symbol-custom" :data-symbol="currencySymbol"> 
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    placeholder="0"
                    v-model="conditionTypeFields.affects.price"
                    @wheel.prevent
                    @keydown.up.prevent
                    @keydown.down.prevent
                  />
                </span>
                <span class="validate-error" style="display: block;">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div v-if="conditionTypeFields.affects.affects === 'Metric'" class="col-lg-3">
          <label class="mb-2">
            Affected Metrics
          </label>
          <div class="mb-2 dropdown-open-top">
            <ValidationProvider rules="required" name="Affected Metrics" v-slot="{ errors }">
              <choices-single
                :options="
                  Object.values(conditionMetrics).map((c, i) => {
                    return { id: i, text: c };
                  })
                "
                v-model="conditionTypeFields.affects.affectedMetric"
                class="charge-unit-dropdown"
              >
                <option value="0">Select</option>
              </choices-single>
              <span class="validate-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="conditionTypeFields.affects.affects === 'Metric'" class="col-lg-3">
          <div class="form-group has-label mb-0">
            <label>Value</label>
            <div>
              <ValidationProvider
                rules="required"
                name="Value"
                v-slot="{ errors }"
              >
                <span>
                  <input
                    type="number"
                    step="any"
                    class="form-control"
                    placeholder="0"
                    v-model="conditionTypeFields.affects.value"
                    @wheel.prevent
                    @keydown.up.prevent
                    @keydown.down.prevent
                  />
                </span>
                <span class="validate-error" style="display: block;">{{
                  errors[0]
                }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div v-if="conditionTypeFields.affects.affects === 'Metric'" class="col-lg-3">
          <label class="mb-2">
            Metric Unit
          </label>
          <div class="mb-2 dropdown-open-top">
            <ValidationProvider rules="required" name="Metric Unit" v-slot="{ errors }">
              <choices-single
                :options="
                  Object.values(metricUnits).map((c, i) => {
                    return { id: i, text: c };
                  })
                "
                v-model="conditionTypeFields.affects.metricUnit"
                class="charge-unit-dropdown"
              >
                <option value="0">Select</option>
              </choices-single>
              <span class="validate-error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import ChoicesSingle from "@/components/SingleSelect";
import {
  conditionMetrics,
  conditionThresholdMetrics,
  metricUnits
} from "@/helpers/utility";

export default {
  computed: {
    conditionMetrics() {
      return conditionMetrics;
    },
    conditionThresholdMetrics() {
      return conditionThresholdMetrics;
    },
    metricUnits() {
      return metricUnits;
    },
    validationRules() {
      return this.isAffectsValid ? 'required' : '';
    }
  },
  components: {
    ChoicesSingle
  },
  props: {
    conditionTypeFields: {
      type: Object
    },
    conditionUnitTypeList: {
      type: Array
    },
    conditionDirection: {
      type: Array
    },
    conditionAffects: {
      type: Array
    },
    selectedConditionTypeDetail: {
      type: Object
    },
    selectedTypeOfCondition: {
      type: String
    },
    currencySymbol: {
      type: String
    }
  },
  data: function() {
    return {
      conditionPricingMethodList: [
        {
          id: 0,
          text: "Fixed"
        },
        {
          id: 1,
          text: "CWT (per hundred weight)"
        },
        {
          id: 2,
          text: "Per Pallet"
        },
        {
          id: 3,
          text: "Cubic Feet"
        }
      ],
      isAffectsValid: false,
    };
  },
  methods: {
    /**
     * Allows only positive numbers and decimal point in the input field.
     * @param {Event} evt - The input event.
     * @returns {boolean} - Returns true if the input is valid, false otherwise.
     */
    positiveNumbersOnly(evt) {
      this.isDuplicate = true;
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
     /**
     * Event handler for changing currency type.
     */
     handleCurrencyDefaultChange(newValue) {
      this.currencyDefault = newValue;
    },
    /**
     * Adds thresholds after setting 'isAffectsValid' flag to false and emitting an event.
     * @emits add-thresholds - Event emitted when thresholds are added.
    */
    async addThresholds() {
      this.$emit("add-thresholds");
    },
    /**
     * Removes a threshold at the specified index by emitting an event.
     * @param {number} thresholdsIndex - The index of the threshold to be removed.
     * @emits remove-thresholds - Event emitted when a threshold is removed.
    */
    removeThresholds(thresholdsIndex) {
      this.$emit("remove-thresholds",thresholdsIndex);
    },
    /**
     * Handles changes in the 'affects' dropdown.
     * Resets relevant fields based on the selected value.
     * @param {string} affects - The selected value from the 'affects' dropdown.
    */
    changeAffects (affects) {
      if (affects == "Price") {
        this.conditionTypeFields.affects = {
          affects: affects,
          pricingMethodType: "",
          price: "",
        }
      } else {
        this.conditionTypeFields.affects = {
          affects: affects,
          affectedMetric: "",
          value: "",
          metricUnit: "",
        }
      }
    }
  }
};
</script>
<style></style>
