<template>
   <div>
    <!-- Origin Dropdown -->
    <div class="row mb-3">
      <div class="col-md-12 col-lg-5">
        <choices-single
          label="Origin"
          v-model="originLocal"
          :options="baseRateOriginList"
          :value="originLocal"
          @input="handleOriginChange"
        >
          <option value="0">Select origin</option>
        </choices-single>
      </div>
    </div>

    <!-- Destination Dropdown -->
    <div class="row mb-3">
      <div class="col-md-12 col-lg-5">
        <choices-single
          label="Destination"
          v-model="destinationLocal"
          :options="baseRateDestinationList"
          :value="destinationLocal"
          @input="handleDestinationChange"
        >
          <option value="0">Select destination</option>
        </choices-single>
      </div>
    </div>
  </div>
</template>

<script>
import { API, masterAPI } from "@/api/API";
import ChoicesSingle from "@/components/SingleSelect";

export default {
  components: {
    ChoicesSingle,
  },
  props: {
    baseRateId: {
      type: Number,
    },
    origin: {
      type: String,
    },
    destination: {
      type: String,
    },
  },
  data() {
    return {
      baseRateOriginList: [],
      baseRateDestinationList: [],
      originLocal: this.origin,
      destinationLocal: this.destination,
    };
  },
  watch: {
    baseRateId(newBaseRateId) {
      if (newBaseRateId != null) {
        this.getOriginDestinationViaApi(newBaseRateId);
      }
    },
  },
  methods: {
    handleOriginChange(newOrigin) {
      // Emit event to parent when the origin changes
      this.$emit('update-origin', newOrigin);
    },
    handleDestinationChange(newDestination) {
      // Emit event to parent when the destination changes
      this.$emit('update-destination', newDestination);
    },
    /**
     * Fetches origin and destination data via an API,
     * processes the data, and updates the `originDestinationList` property.
     */
    async getOriginDestinationViaApi(baseRateId) {
      try {
        const response = await masterAPI(
          API.API_ENDPOINT.baseRateLocations(baseRateId),
          API.API_METHOD.get
        );

        if (response.status === 200) {
          const data = response.data;
          this.baseRateOriginList = data.origins.map(option => ({
            id: option.key,
            text: option.name
          }));
          this.baseRateDestinationList = data.destinations.map(option => ({
            id: option.key,
            text: option.name
          }));
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    }
  },
  mounted() {
    if (this.baseRateId !== null) {
      this.getOriginDestinationViaApi(this.baseRateId);
    }
  }
};
</script>
