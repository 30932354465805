import "@/assets/scss/argon-design-system.scss";
import "@/assets/vendor/font-awesome/css/font-awesome.css";
import "../assets/vendor/nucleo/css/nucleo-icons.css";
import globalDirectives from "./globalDirectives";
import globalComponents from "./globalComponents";
import globalMixins from "./globalMixins";

import { configure } from "vee-validate";
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

export default {
  install(Vue) {
    Vue.use(globalDirectives);
    Vue.use(globalComponents);
    Vue.use(globalMixins);
    configure({
      classes: {
        valid: "is-valid",
        invalid: "is-invalid",
        dirty: ["is-dirty", "is-dirty"]
      }
    });
  }
};
