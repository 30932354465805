<template>
  <div class="wrapper">
    <div class="page-header register-header">
      <div
        class="container"
        v-bind:class="{ 'right-panel-active': isActive }"
        id="container"
      >
        <div class="form-container sign-up-container">
          <form action="javascript:;">
            <img
              class="m-signin-signup-img"
              src="../assets/welcome.png"
              style="display: none;"
            />
            <h2>Create New Account</h2>
            <!-- <span class="text-default mb-4"
              >Use your company email for registration</span
            > -->
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Name"
                addonLeftIcon="ni ni-circle-08"
                v-model="registerUserName"
              ></vue-input>
            </div>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Organization Name"
                addonLeftIcon="fa fa-users"
                v-model="organizationName"
                :readonly="disableOrgName"
              ></vue-input>
            </div>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Email"
                addonLeftIcon="ni ni-email-83"
                v-model="registerUserEmail"
                :readonly="disableEmail"
              ></vue-input>
            </div>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Password"
                type="password"
                addonLeftIcon="ni ni-lock-circle-open"
                v-model="registerUserPassword"
              ></vue-input>
            </div>

            <!-- Rdio btns start -->
            <div class="form-group mb-3">
              <label class="organization-type-text mb-2"
                >Organization type</label
              >
              <div class="d-flex">
                <base-radio
                  name="carrier"
                  class="mr-3 user-mode-radio"
                  v-model="userMode"
                >
                  Carrier
                </base-radio>
                <base-radio
                  name="broker"
                  class="user-mode-radio"
                  v-model="userMode"
                >
                  Shipper/3PL
                </base-radio>
              </div>
            </div>
            <!-- Radio btns end -->
            <base-button
              class="btn-theme-24876E"
              type="success"
              v-on:click="signUp"
              v-on:keyup.enter="signUp"
              >Sign Up
            </base-button>

            <div class="m-signin-signup-link mt-3">
              Already have account?
              <a @click="isActive = false">Sign in</a>
            </div>
          </form>
        </div>
        <div class="form-container sign-in-container">
          <form @submit.prevent="signIn">
            <img
              class="m-signin-signup-img"
              src="../assets/welcome.png"
              style="display: none;"
            />
            <h2 class="mb-3">Sign in to tariff</h2>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Email"
                addonLeftIcon="ni ni-email-83"
                v-model="signInEmail"
              ></vue-input>
            </div>
            <div class="form-group mb-0">
              <vue-input
                alternative
                placeholder="Password"
                type="password"
                addonLeftIcon="ni ni-lock-circle-open"
                v-model="signInPassword"
              ></vue-input>
            </div>

            <button type="submit" class="btn btn-success btn-theme-24876E">
              Continue
            </button>

            <router-link to="/reset" class="dropdown-item forget-password mt-3">
              Forgot your password?
            </router-link>
            <!-- <base-button
              type="submit"
              class="mt-3"
              v-on:click="signIn"
              >Sign In
            </base-button> -->

            <div class="m-signin-signup-link mt-5">
              Create account.
              <a @click="isActive = true">Sign up</a>
            </div>
          </form>
        </div>
        <div class="overlay-container">
          <div class="overlay">
            <div class="overlay-panel overlay-left">
              <img src="../assets/welcome.png" />
              <h3>Welcome</h3>
              <!-- <p>
                Please login with your credentials
              </p> -->
              <base-button
                type="success"
                class="bw-100 btn-theme-24876E"
                @click="isActive = false"
                >Sign In
              </base-button>
            </div>

            <div class="overlay-panel overlay-right">
              <img src="../assets/welcome.png" />
              <h3>Welcome</h3>
              <p>
                You are a few steps away from building the best tariffs and
                creating better quotes for your customers easier than ever
                before.
              </p>
              <base-button
                type="success"
                class="bw-100 btn-theme-24876E"
                @click="isActive = true"
                >Sign Up
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, masterAPI } from "@/api/API";
import { mapActions, mapGetters } from "vuex";

export default {
  bodyClass: "register-page",
  data() {
    return {
      isActive: false,
      signInEmail: "",
      signInPassword: "",
      registerUserName: "",
      organizationName: "",
      registerUserEmail: "",
      registerUserPassword: "",
      signInError: "",
      disableOrgName: false,
      disableEmail: false,
      userMode: ""
    };
  },
  computed: {
    ...mapActions(["getProfileData"]),
    ...mapGetters(["accountIsBroker"])
  },
  methods: {
    signIn: async function() {
      let bodyPayload = JSON.stringify({
        email: this.signInEmail,
        password: this.signInPassword
      });

      let response = await masterAPI(
        API.API_ENDPOINT.login,
        API.API_METHOD.post,
        undefined,
        bodyPayload
      );

      if (response.status === 200) {
        this.$toast.success("Login Successful", { timeout: 100 });
        localStorage.setItem("id_token", response.data.id_token);
        await this.getAccounts();
      } else {
        let error = response.data.message;
        this.$toast.error(error.replaceAll('"', ""));
      }
    },
    signUp: async function() {
      let bodyPayload = JSON.stringify({
        email: this.registerUserEmail,
        firstName: this.registerUserName,
        organizationName: this.organizationName,
        password: this.registerUserPassword,
        mode: this.userMode
      });

      let response = await masterAPI(
        API.API_ENDPOINT.signup,
        API.API_METHOD.post,
        undefined,
        bodyPayload
      );

      if (response.status == 200) {
        this.$toast.success("Registration successful", { timeout: 100 });
        localStorage.setItem("id_token", response.data.id_token);
        // TODO: get accounts on signUp
        await this.getProfile();
      } else {
        let error = response.data.message;
        this.$toast.error(error.replaceAll('"', ""));
      }
    },
    async getAccounts() {
      const response = await masterAPI(
        API.API_ENDPOINT.getAccounts,
        API.API_METHOD.get
      );
      if (response.status >= 200 && response.status < 400) {
        const accounts = response.data;
        if (accounts.length === 1) {
          await this.$store.dispatch("getProfileData");
          localStorage.setItem("accountId", accounts[0].id);
          this.$router.push({
            path: "/viewallsection",
            name: "viewallsection"
          });
        } else {
          await this.$store.dispatch("getProfileData");
          localStorage.setItem("accountId", accounts[0].id);
          this.$router.push({
            path: "/tenant"
          });
        }
      }
    },
    getProfile: async function() {
      await this.$store.dispatch("getProfileData");
      setTimeout(() => {
        this.$router
          .push({ path: "/viewallsection", name: "viewallsection" })
          .catch(() => {});
      }, 300);
    }
  },
  created() {
    if (location.href.includes("companyName")) {
      this.isActive = true;
      if (location.href.includes("email")) {
        this.disableOrgName = true;
        this.disableEmail = true;

        let email = location.href.split("email=")[1].trim();
        let decodedEmail = Buffer.from(email, "base64").toString("ascii");
        this.registerUserEmail = decodedEmail.replace("#/", "");

        let companyName = location.href.split("companyName=")[1].trim();
        companyName = companyName.split("/")[0].trim();
        let decodedCompanyName = Buffer.from(companyName, "base64").toString(
          "ascii"
        );
        this.organizationName = decodedCompanyName.replace("#/", "");
      } else {
        this.disableOrgName = true;

        let companyName = location.href.split("companyName=")[1].trim();
        let decodedCompanyName = Buffer.from(companyName, "base64").toString(
          "ascii"
        );
        this.organizationName = decodedCompanyName.replace("#/", "");
      }
    }
  }
};
</script>

<style></style>
