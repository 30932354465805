<template>
  <div class="mt-3">
    <base-checkbox
      class="mb-2"
      v-model="showAdditionalInput"
      :checked="showAdditionalInput"
    >
      Lane uses Interline Carrier Partner
    </base-checkbox>
    <div class="row mb-3" v-if="showAdditionalInput">
      <div class="col-md-6 interline-partner-dropdown">
        <choices-single
          :options="partnerOptions"
          :key="selectedInterlinePartner"
          v-model="selectedInterlinePartner"
          @input="onPartnerChange"
        >
          <option>{{
            selectedInterlinePartner || "Select Interline Partner"
          }}</option>
        </choices-single>
      </div>
      <div class="col-md-2 input-v1 percentage-input-container">
        <span class="percentage-sign">%</span>
        <input
          type="number"
          step="any"
          class="form-control"
          placeholder="Percent"
          v-model="percentage"
          @wheel.prevent
          @keydown.up.prevent
          @keydown.down.prevent
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChoicesSingle from "@/components/SingleSelect";
export default {
  components: {
    ChoicesSingle
  },
  props: {
    interlinePartners: {
      type: Array
    },
    existingPercentage: {
      type: Number
    },
    existingPartnerId: {
      type: Number
    }
  },

  watch: {
    existingPartnerId(newVal) {
      if (this.selectedInterlinePartnerId !== newVal) {
        this.selectedInterlinePartner = this.partnerOptions.find(
          ({ id }) => id === newVal
        ).text;
        this.selectedInterlinePartnerId = newVal;
        this.showAdditionalInput = true;
      }
    },
    existingPercentage(newVal) {
      if (this.percentage !== newVal) {
        this.percentage = newVal;
      }
    }
  },
  computed: {
    partnerOptions() {
      return this.interlinePartners.map(option => ({
        id: option.id,
        text: option.name
      }));
    }
  },
  data() {
    return {
      showAdditionalInput: false,
      selectedInterlinePartner: "",
      selectedInterlinePartnerId: null,
      percentage: null
    };
  },
  methods: {
    onPartnerChange(value) {
      const selectedOption = this.partnerOptions.find(
        option => option.text === value
      );
      if (selectedOption) {
        this.selectedInterlinePartnerId = selectedOption.id;
      }
    },
    validate() {
      if (!this.showAdditionalInput) {
        return true;
      }
      if (
        this.percentage < 1 ||
        this.percentage > 100 ||
        this.percentage === null ||
        !this.selectedInterlinePartner
      ) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
.interline-partner-dropdown :deep(.choices__list--dropdown) {
  max-height: 200px;
}
.interline-partner-dropdown :deep(.choices__list--dropdown .choices__list) {
  max-height: none; /* Remove any height restrictions */
  width: 100%;
}
.interline-partner-dropdown :deep(.choices__list--dropdown.is-active) {
  overflow-y: auto;
}
.percentage-input-container {
  position: relative;
}

.percentage-sign {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  pointer-events: none;
}
</style>
