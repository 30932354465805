<template>
  <div class="side-bar-menu">
    <!-- Dynamic menu items -->

    <ul class="menu-list">
      <li v-for="(menuItem, index) in filteredMenuItems" :key="index">
        <span v-if="menuItem.type === 'menu'">
          <a
            @click.prevent="
              updateScreen(menuItem.name.toLowerCase(), menuItem.preselected)
            "
            :class="{
              'btn-success active-btn btn-theme-24876E':
                activeMenuName === menuItem.name &&
                menuItem.preselected === preselected,
              'btn-menu-grey':
                activeMenuName !== menuItem.name ||
                menuItem.preselected !== preselected
            }"
            class="btn"
          >
            <img :src="getMenuIcon(menuItem)" />&nbsp;
            {{ menuItem.label }}
          </a>
        </span>
        <div v-else class="mt-4 mb-4 my-work-space">
          {{ menuItem.label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { nonBrokerMenu } from "@/helpers/utility";

export default {
  name: "left-panel",
  props: {
    activeMenuName: {
      type: String
    }
  },
  data() {
    return {
      menuItems: [
        {
          type: "menu",
          name: "create-custom-fields",
          label: "Sections/fields",
          preselected: "Lane",
          icon: "plus-active.svg"
        },
        {
          type: "menu",
          name: "viewallsection",
          label: "All sections",
          preselected: "Lane",
          icon: "eye-active.svg"
        },
        {
          type: "menu",
          name: "create-custom-fields",
          label: "Customers/group",
          preselected: "Customer or Customer Group",
          icon: "plus-active.svg"
        },
        {
          type: "menu",
          name: "viewallsection",
          label: "Customers/group",
          preselected: "Customer or Customer Group",
          icon: "eye-active.svg"
        },
        {
          type: "menu",
          name: "create-tariff",
          label: "Build Tariff",
          preselected: "Lane",
          icon: "plus-active.svg"
        },
        {
          type: "menu",
          name: "view-all-tariff",
          label: "View Tariff",
          preselected: "Lane",
          icon: "eye-active.svg"
        },
        {
          type: "menu",
          name: "view-all-quotes",
          label: "Quotes",
          preselected: "Lane",
          icon: "eye-active.svg"
        }
      ],
      preselected: "Lane"
    };
  },
  computed: {
    ...mapGetters(["accountIsBroker"]),
    filteredMenuItems() {
      return this.menuItems.filter(menuItem => {
        if (this.accountIsBroker) {
          // Hide menu items with specified labels if account is a broker
          return !nonBrokerMenu.includes(menuItem.label);
        }
        return true; // If the user is a carrier, show all menu items
      });
    }
  },
  methods: {
    updateScreen: function(screenName, preSelectedScreen) {
      if (this.$route.name == screenName) {
        this.preselected = preSelectedScreen;
      } else {
        this.$router.push({
          name: screenName,
          params: { preselected: preSelectedScreen }
        });
      }
    },
    // Define a method to get the appropriate icon based on active state
    getMenuIcon(menuItem) {
      return this.activeMenuName === menuItem.name &&
        menuItem.preselected === this.preselected
        ? require(`../assets/img/${menuItem.icon}`)
        : require(`../assets/img/${menuItem.icon.replace(
            "-active.svg",
            ".svg"
          )}`);
    }
  },
  async created() {
    if (this.$route.params.preselected) {
      this.preselected = this.$route.params.preselected;

      if (this.preselected != "Customer or Customer Group") {
        this.menuItems[0].preselected = this.preselected;
        this.menuItems[1].preselected = this.preselected;
      }
    }
  }
};
</script>

<style></style>
