<template>
  <div
    class="tenant-create d-flex align-items-center tariff-app-container-height"
  >
    <div class="container">
      <div class="card">
        <div class="card-header">
          <div class="text-center mt-3">
            <h4 class="font-weight-bold text-primary">Create an Account</h4>
          </div>
        </div>
        <div class="card-body">
          <div>
            <label class="mb-1">Account Name</label>
            <base-input type="text" placeholder="Required" v-model="name" />
          </div>
          <div>
            <label class="mb-1">External ID</label>
            <base-input
              type="text"
              placeholder="Optional"
              v-model="externalId"
            />
          </div>
          <div class="form-group mb-3 ml-1">
            <label class="mb-1">Account type</label>
            <div class="d-flex">
              <base-radio
                name="carrier"
                class="mr-3 user-mode-radio"
                v-model="accountMode"
              >
                Carrier
              </base-radio>
              <base-radio
                name="broker"
                class="user-mode-radio"
                v-model="accountMode"
              >
                Shipper/3PL
              </base-radio>
            </div>
          </div>
          <div class="d-flex mt-2 justify-content-end">
            <button
              type="button"
              class="btn btn-link mr-2"
              @click="handleCancel"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-default"
              :disabled="isFetching"
              @click="handleCreate"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, masterAPI } from "../../api/API";
export default {
  data() {
    return {
      accountMode: "carrier",
      name: "",
      externalId: "",
      isFetching: false
    };
  },
  methods: {
    handleCancel() {
      this.$router.push({ name: "tenant" });
    },
    async handleCreate() {
      if (!this.name) {
        this.$toast.error("Please enter an account name.");
        return;
      }
      this.isFetching = true;
      try {
        const createAccountRes = await masterAPI(
          API.API_ENDPOINT.account,
          API.API_METHOD.post,
          null,
          JSON.stringify({
            name: this.name,
            mode: this.accountMode,
            metadata: {
              externalId: this.externalId
            }
          })
        );
        if (createAccountRes.status >= 200 && createAccountRes.status < 400) {
          const accountId = createAccountRes.data.id;
          const switchAccountRes = await masterAPI(
            API.API_ENDPOINT.switchAccount,
            API.API_METHOD.post,
            null,
            JSON.stringify({ accountId: accountId })
          );
          localStorage.setItem("id_token", switchAccountRes.data.id_token);
          localStorage.setItem("accountId", accountId);
          await this.$store.dispatch("getProfileData");
          await this.$store.dispatch("getAccountData");
          this.isFetching = false;
          this.$router.push({
            name: "viewallsection"
          });
        }
      } catch (error) {
        this.$toast.error("Something went wrong creating an account");
        this.isFetching = false;
      }
    }
  }
};
</script>

<style>
@media (min-width: 448px) {
  .tenant-create .container {
    max-width: 448px;
  }
}
</style>
