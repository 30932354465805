import Vue from "vue";
import Vuex from "vuex";
import { API, masterAPI } from "@/api/API";
import { accountModes } from "@/helpers/utility";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {},
    account: {},
    currencyType: "USD"
  },
  mutations: {
    setUser(state, user) {
      state = user;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setCurrencyType(state, currencyType) {
      state.currencyType = currencyType;
    }
  },
  actions: {
    async getProfileData({ commit, state }) {
      let response = await masterAPI(
        API.API_ENDPOINT.user,
        API.API_METHOD.get,
        undefined,
        undefined
      );
      if (response.status == 200) {
        let user = response.data;
        commit("setUser", Object.assign(state, { user }));
      }
    },
    async getAccountData({ commit }) {
      const response = await masterAPI(
        API.API_ENDPOINT.getAccounts,
        API.API_METHOD.get
      );
      if (response.status >= 200 && response.status < 400) {
        const accountId = Number(localStorage.getItem("accountId"));
        const account = response.data.find(account => account.id === accountId);
        commit("setAccount", account);
      }
    },
    updateAccount({ commit }, account) {
      commit("setAccount", account);
    },
    updateCurrencyType({ commit }, currencyType) {
      commit("setCurrencyType", currencyType);
    }
  },
  getters: {
    accountIsBroker: state => state.account.mode == accountModes.BROKER,
    user: state => state.user,
    account: state => state.account,
    currencyType: state => state.currencyType
  }
});

export default store;
