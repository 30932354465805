var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-bar-menu"},[_c('ul',{staticClass:"menu-list"},_vm._l((_vm.filteredMenuItems),function(menuItem,index){return _c('li',{key:index},[(menuItem.type === 'menu')?_c('span',[_c('a',{staticClass:"btn",class:{
            'btn-success active-btn btn-theme-24876E':
              _vm.activeMenuName === menuItem.name &&
              menuItem.preselected === _vm.preselected,
            'btn-menu-grey':
              _vm.activeMenuName !== menuItem.name ||
              menuItem.preselected !== _vm.preselected
          },on:{"click":function($event){$event.preventDefault();_vm.updateScreen(menuItem.name.toLowerCase(), menuItem.preselected)}}},[_c('img',{attrs:{"src":_vm.getMenuIcon(menuItem)}}),_vm._v("  "+_vm._s(menuItem.label)+" ")])]):_c('div',{staticClass:"mt-4 mb-4 my-work-space"},[_vm._v(" "+_vm._s(menuItem.label)+" ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }