import { API, masterAPI } from "@/api/API";
import moment from "moment";
import "../types.js";

/**
 * Represents the key used for accessing fuel surcharge information.
 */
export const fuelSurchargeKey = "fuelSurcharge";
/**
 * User mode constants.
 */
export const accountModes = {
  BROKER: "broker",
  CARRIER: "carrier"
};

/**
 * Route Paths to hide in broker.
 */
export const brokerRestrictedPaths = [
  "/create-tariff",
  "/view-all-tariff",
  "/customer/:view/:id",
  "/tariff/duplicate/:id",
  "/tariff/edit/:id"
];

/**
 * Menu items to hide in broker.
 */
export const nonBrokerMenu = ["Customers/group", "Build Tariff", "View Tariff"];

/**
 * Section list constants.
 */
export const sectionList = {
  lane: "Lane",
  accessorial: "Accessorial",
  fuelSurcharge: "Fuel Surcharge",
  conditions: "Condition",
  customerOrCustomerGroup: "Customer or Customer Group",
  classBaseRate: "Class - Base Rate",
  routing: "Routing",
  interlinePartner: "Interline Partner"
};

/**
 * Price methods mode constants.
 */
export const priceMethodMode = {
  dollar: "dollar",
  percentage: "percentage"
};

/**
 * Currency list constants.
 */
export const currencyList = [
  {
    id: 0,
    text: "USD"
  },
  {
    id: 1,
    text: "CAD"
  },
  {
    id: 2,
    text: "MXN"
  }
];

/**
 * Data type constants.
 */
export const dataType = {
  fixed: "Fixed",
  percentage: "Percentage",
  fixedPlusPercentage: "Fixed + Percentage",
  perhundred: "Perhundred",
  percentageOfFreightCharges: "Percentage of Freight Charges",
  weightRange: "Weight Range"
};

/**
 * Pricing method type constants.
 */
export const pricingMethodType = {
  cwt: "CWT (per hundred weight)",
  volume: "Volume",
  linearFoot: "Linear Foot",
  weightRange: "Weight Range",
  perPallet: "Per Pallet",
  fixed: "Fixed",
  class: "Class",
};

/**
 * Unit list constants.
 */
export const unitList = {
  weight: "Weight",
  price: "Price",
  hour: "Hour",
  time: "Time"
};

/**
 * Unit type constants.
 */
export const unitType = {
  kgs: "Kgs",
  lbs: "Lbs"
};

/**
 * Constants for condition types.
 */
export const conditionType = {
  length: "Length",
  width: "Width",
  height: "Height",
  weight: "Weight",
  volume: "Volume",
  density: "Density",
  commodity: "Commodity"
};

/**
 * Unit types for the Linear Foot Rule.
 */
export const linearFootRuleUnitType = [
  {
    id: 0,
    text: "Inches"
  },
  {
    id: 1,
    text: "Feet"
  }
];

export const conditionDirection = [
  {
    id: 0,
    text: "Less than"
  },
  {
    id: 1,
    text: "Greater than"
  }
];

export const conditionAffects = [
  {
    id: 0,
    text: "Metric"
  },
  {
    id: 1,
    text: "Price"
  }
];

export const conditionThresholdMetrics = {
  length: "Length",
  width: "Width",
  height: "Height",
  weight: "Weight"
};

export const conditionMetrics = {
  length: "Length",
  width: "Width",
  height: "Height",
  weight: "Weight",
  volume: "Volume",
  density: "Density",
  commodity: "Commodity"
};

export const metricUnits = {
  inches: "Inches",
  feet: "Feet",
  lbsPerFoot: "Lbs per foot"
};

/**
 * Unit types for the Cube Rule.
 */
export const cubeRuleUnitType = [
  {
    id: 0,
    text: "Cubic Feet"
  },
  {
    id: 1,
    text: "Lbs"
  }
];

/**
 * Unit types for the Height Rule.
 */
export const heightRuleUnitType = [
  {
    id: 0,
    text: "Inches"
  },
  {
    id: 1,
    text: "Feet"
  }
];

/**
 * Custom Array for Lane BaseRate Class List
 */
export const classDataBaseLane = [
  {
    name: "Class 50",
    key: "class50",
    required: true
  },
  {
    name: "Class 55",
    key: "class55",
    required: true
  },
  {
    name: "Class 60",
    key: "class60",
    required: true
  },
  {
    name: "Class 65",
    key: "class65",
    required: true
  },
  {
    name: "Class 70",
    key: "class70",
    required: true
  },
  {
    name: "Class 77.5",
    key: "class77",
    required: true
  },
  {
    name: "Class 85",
    key: "class85",
    required: true
  },
  {
    name: "Class 92.5",
    key: "class92",
    required: true
  },
  {
    name: "Class 100",
    key: "class100",
    required: true
  },
  {
    name: "Class 110",
    key: "class110",
    required: true
  },
  {
    name: "Class 125",
    key: "class125",
    required: true
  },
  {
    name: "Class 150",
    key: "class150",
    required: true
  },
  {
    name: "Class 175",
    key: "class175",
    required: true
  },
  {
    name: "Class 200",
    key: "class200",
    required: true
  },
  {
    name: "Class 250",
    key: "class250",
    required: true
  },
  {
    name: "Class 300",
    key: "class300",
    required: true
  },
  {
    name: "Class 400",
    key: "class400",
    required: true
  },
  {
    name: "Class 500",
    key: "class500",
    required: true
  }
];

/**
 * Constants for charge unit types.
 */
export const chargeUnitType = {
  perPallet: "Per Pallet",
  fixed: "Fixed",
  perhundred: "Perhundred",
  percentageOfFreightCharges: "Percentage of Freight Charges",
  range: "Range"
};

/**
 * Keys representing different types of conditions.
 */
export const conditionsTypeKey = {
  linearFootRule: "linearFootRule",
  cubeRule: "cubeRule",
  heightRule: "heightRule"
};

/**
 * Object defining the number of items per page for pagination in different tables.
 */
export const tablePaginationPerPageItem = {
  customers: 5,
  customerGroups: 5
};

/**
 * Fetches account module data from a specified API endpoint.
 * @param {string} apiEndPoint - The API endpoint to fetch data from.
 * @returns {Promise<Array>} - A promise that resolves to an array of dropdown options.
 */
export async function accountModuleApi(apiEndPoint) {
  let response = await masterAPI(
    apiEndPoint,
    API.API_METHOD.get,
    undefined,
    undefined
  );

  if (response.status == 200) {
    return createDropdownOption(response.data);
  } else {
    return [];
  }
}

/**
 * Creates dropdown options from a list of items.
 * @param {Array} list - List of items to create dropdown options from.
 * @returns {Array} - An array of dropdown options with id, text, and itemDetail properties.
 */
export function createDropdownOption(list) {
  let optionList = [];
  for (const [index, item] of list.entries()) {
    optionList.push({
      id: index,
      text: item.name,
      itemDetail: item
    });
  }
  return optionList;
}

/**
 * Gets the item detail from a dropdown options list based on the option name.
 * @param {Array} list - List of dropdown options.
 * @param {string} optionName - Name of the selected option.
 * @returns {Object|null} - The item detail object or null if not found.
 */
export function getOptionDetailByName(list, optionName) {
  let selectedOptionDetail = list.filter(filterItem => {
    if (filterItem.text == optionName) {
      return filterItem;
    }
  });

  return selectedOptionDetail.length > 0
    ? selectedOptionDetail[0].itemDetail
    : null;
}

/**
 * Gets the method key by name from a list of dropdown options.
 * @param {Array} list - List of dropdown options.
 * @param {string} key - Key to search for.
 * @returns {string|null} - The method key or null if not found.
 */
export function getMethodKeyByName(list, key) {
  const foundItem = list.find(item => item.itemDetail.key === key);
  return foundItem ? foundItem.itemDetail.name : null;
}

/**
 * Gets the method key by item object from a list of dropdown options.
 * @param {Array} list - List of dropdown options.
 * @param {string} key - Key to search for.
 * @returns {Object|null} - The item detail object or null if not found.
 */
export function getMethodKeyByItemObject(list, key) {
  const foundItem = list.find(item => item.itemDetail.key === key);
  return foundItem ? foundItem.itemDetail : null;
}

/**
 * Gets the accessorial name by key from a list of dropdown options.
 * @param {Array} list - List of dropdown options.
 * @param {string} name - Name to search for.
 * @returns {string|null} - The accessorial name or null if not found.
 */
export function getAccessorialNameByKey(list, name) {
  const foundItem = list.find(item => item.itemDetail.name === name);
  return foundItem ? foundItem.itemDetail.key : null;
}

/**
 * Gets the accessorial key by name from a list of dropdown options.
 * @param {Array} list - List of dropdown options.
 * @param {string} key - Key to search for.
 * @returns {string|null} - The accessorial key or null if not found.
 */
export function getAccessorialKeyByName(list, key) {
  const foundItem = list.find(item => item.itemDetail.key === key);
  return foundItem ? foundItem.itemDetail.name : null;
}

/**
 * Extracts the 'type' and 'item' properties from each object in the input list.
 *
 * @param {Array} list - The input list of objects.
 * @returns {Array} An array of objects containing only the 'type' and 'item' properties.
 */
export function getTypeItem(list) {
  return list.map(fieldItem => {
    return {
      type: fieldItem.type,
      item: fieldItem.item
    };
  });
}

/**
 * Retrieves the currency symbol based on the provided currency code.
 *
 * @param {string} value - The currency code.
 * @returns {string} The corresponding currency symbol.
 */
export function getCurrencyType(value) {
  switch (value) {
    case "USD":
      return "$";
    case "MXN":
      return "MX$";
    case "CAD":
      return "CA$";
    default:
      return "";
  }
}

/**
 * Fetches all customers or customer groups from the API based on the specified flag.
 * @param {boolean} isCustomerGroupApi - A flag indicating whether to fetch customer groups (true) or customers (false).
 * @returns {Promise<Array>} A promise that resolves to an array of customer objects or customer group objects.
 */
export async function getAllCustomerOrCustomerGroupApi(
  isCustomerGroupApi,
  apiKey
) {
  let apiEndPoint = isCustomerGroupApi
    ? API.API_ENDPOINT.customerGroups
    : API.API_ENDPOINT.customers;

  let response = await masterAPI(
    apiEndPoint + "/" + apiKey,
    API.API_METHOD.get,
    undefined,
    undefined
  );

  if (response.status == 200) {
    return response.data;
  } else {
    return [];
  }
}

/**
 * Filters an array of customer objects based on a list of customer IDs.
 * @param {Array} customerList - An array of customer objects to filter.
 * @param {Array} customerIdList - An array of customer IDs to use for filtering.
 * @returns {Array} An array of filtered customer objects based on the provided customer IDs.
 */
export function filterCustomerObjectsById(customerList, customerIdList) {
  return customerList.filter(obj =>
    customerIdList.some(id => parseInt(id) === obj.id)
  );
}

/**
 * Formats a date string using the "MMM Do, YYYY" format.
 * @param {string} date - The date string to be formatted.
 *
 * @returns {string} - The formatted date string.
 */
export function setDateFormat(date) {
  return moment(date).format("MMM Do, YYYY");
}

/**
 * Retrieves a list of EIA base rates.
 * @returns {Promise<Array<Object>>} A promise that resolves to an array of objects representing EIA base rates.
 */
export async function getEiaBaseRatesList() {
  let response = await masterAPI(
    API.API_ENDPOINT.eiaBaseRates,
    API.API_METHOD.get,
    undefined,
    undefined
  );

  if (response.status == 200) {
    let list = response.data;
    let optionList = [];

    for (const [index, item] of list.entries()) {
      optionList.push({
        id: index,
        name: item.name,
        key: item.key,
        itemDetail: item
      });
    }

    return optionList;
  } else {
    return [];
  }
}

/**
 * Retrieves the user's API key.
 * @param {Object} store - The Vuex store object.
 *
 * @returns {Promise<string>} A promise that resolves to the user's API key.
 */
export async function getUserApiKey(store) {
  // Check if the API key is available in the store
  if (!store.getters.user?.apiKey) {
    // If not, fetch the profile data to populate the API key
    await store.dispatch("getProfileData");
  }
  // Retrieve the API key from the store after fetching profile data
  const apiKey = store.getters.user?.apiKey;
  if (!apiKey) {
    throw new Error(
      "User API key is still undefined after fetching profile data."
    );
  }
  // Return the valid API key
  return apiKey;
}

/**
 * calculates a new price based on the base value, tariff value, priceMode
 *
 * @param {number|string} baseValue - The original value.
 * @param {number|string} tariffValue - The tariff value.
 * @param {string} priceMode - The mode of calculation, either "percentage" or "dollar".
 *
 * @returns {string} - The calculated price, rounded to two decimal places.
 */
export function calculatePrice(baseValue, tariffValue, priceMode) {
  // Parse base value and tariff value into floats
  const parsedTariffValue = parseFloat(tariffValue);
  const parsedBaseValue = parseFloat(baseValue || 0);

  if (priceMode === "percentage") {
    return (
      parsedBaseValue +
      parsedBaseValue * (parsedTariffValue / 100)
    ).toFixed(2);
  } else if (priceMode === "dollar") {
    return (parsedBaseValue + parsedTariffValue).toFixed(2); // Dollar logic
  }
  return (parsedBaseValue + parsedTariffValue).toFixed(2); // Dollar logic
}
/**
 * Error message for invalid percentage pricing switch
 */
export const invalidPercentagePricingMessage =
  "You cannot switch to percentage for this pricing method.";

// Function to get a list of restricted types for percentage pricingMode
export function getPercentageRestrictedTypes() {
  return [
    "Percentage of Freight Charges",
    "Weight Range",
    "Fixed + Percentage"
  ];
}

// Function to determine if an item should only allow percentage pricing
export function allItemsHavePercentagePricing(item) {
  const restrictedTypes = getPercentageRestrictedTypes();
  return item.dataTypeFields.some(dataTypeField => {
    if (restrictedTypes.includes(dataTypeField.type)) {
      if (dataTypeField.type === "Weight Range") {
        return dataTypeField.item.every(
          fieldItem => fieldItem.pricingMethod === "Percentage"
        );
      }
      return true;
    }
    return false;
  });
}

/**
 * Build the origin/destination/subcities from the Lane.
 * This is to account for the user attaching a prebuilt Routing.
 * @param {Object} lane The lane object from the API.
 * @returns {{
 *   origin: string, destination: string,
 *   subCities: { origin: { city: string }[], destination: { city: string }[]},
 *   routings: { origin: Routing | null, destination: Routing | null }
 * }}
 */
export function getLocationData(lane) {
  let origin = lane.origin;
  let destination = lane.destination;
  let subCities = lane.subCity;
  const routings = { origin: null, destination: null };
  if (lane.originRoutingId) {
    const routing = lane.originRouting;
    origin = routing.parentCity;
    routings.origin = routing;
    subCities.origin = routing.subCities;
  }
  if (lane.destinationRoutingId) {
    const routing = lane.destinationRouting;
    destination = routing.parentCity;
    routings.destination = routing;
    subCities.destination = routing.subCities;
  }
  return { origin, destination, subCities, routings };
}
