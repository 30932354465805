<template>
  <div>
    <div class="mt-3" v-if="additionalInputAvailable">
      <div class="row mb-3">
        <base-checkbox
          class="mb-2 additional-checkbox  col-12 col-md-5 col-lg-3"
          v-model="showAdditionalInput"
          :checked="showAdditionalInput"
        >
          Set Additional Fixed charge
        </base-checkbox>
        <span class="input-v1 price-charge-textBox" v-if="showAdditionalInput">
          <base-input
            type="text"
            placeholder="Additional Charge Label"
            v-model="additionalValueLabel"
          >
          </base-input>
        </span>
        <span class="data-type-input left price-charge-textBox additional-charge-price" v-if="showAdditionalInput">
          <input
            type="number"
            step="any"
            class="form-control"
            placeholder="00.00"
            v-model="additionalValue"
            @input="changeValue('additionalValue')"
            @blur="decimalValueValidation('additionalValue')"
            @wheel.prevent
            @keydown.up.prevent
            @keydown.down.prevent
          />
          <div v-if="additionalValueError !== ''" class="validate-error">
            {{ additionalValueError }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loader",
  props: {
    additionalInputAvailable: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      showAdditionalInput: false,
      additionalValue: "",
      additionalValueLabel: "",
      additionalValueError: ""
    };
  },
  methods: {
    /**
     * Change the value by truncating to 2 decimal places.
     *
     * @param {string} params - The parameter to be changed.
     */
    changeValue(params) {
      let fixedValue = this[params];
      if (fixedValue.includes(".")) {
        const parts = fixedValue.split(".");
        if (parts[1] && parts[1].length > 2) {
          this[params] = `${parts[0]}.${parts[1].substr(0, 2)}`;
        }
      }
      this.additionalValueValidation();
    },
    /**
     * Validate and add decimal places if missing.
     *
     * @param {string} params - The parameter to be validated.
     */
    decimalValueValidation(params) {
      let fixedValue = this[params];
      if (fixedValue !== "" && !fixedValue.includes(".")) {
        this[params] = fixedValue + ".00";
      }
      this.additionalValueValidation();
    },
    /**
     * Validates the charge values.
     * Sets corresponding error messages if validation fails.
     */
    additionalValueValidation() {
      this.additionalValueError = "";

      if (this.showAdditionalInput && this.additionalValue === "") {
        this.additionalValueError =
          "The Additional Fixed charge field is required";
      }

      if (!this.showAdditionalInput) {
        this.additionalValue = "";
      }
    },
    /**
     * Checks if the form passes the additionalValueValidation.
     * Returns true if the form is valid, otherwise false.
     * @returns {boolean} - Indicates whether the form is valid.
     */
    checkAdditionalValueValidation() {
      this.additionalValueValidation();
      let isValid = true;
      if (this.additionalValueError !== "") {
        isValid = false;
      }
      return isValid;
    }
  }
};
</script>
<style></style>
