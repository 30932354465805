<template>
  <div>
    <interline-partner-form :moduleView="moduleType" :isCreate="true" />
  </div>
</template>

<script>
import InterlinePartnerForm from "./InterlinePartnerForm";
export default {
  bodyClass: "landing",
  components: {
    InterlinePartnerForm
  },
  props: {
    moduleType: {
      type: String
    }
  }
};
</script>
