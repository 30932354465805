import axios from "axios";
import { Config } from "@/config/config";

export const API = {
  // API base url
  API_BASE_URL: Config.apiUrl,

  // API version
  API_VERSION: "/v1/",

  // API end point
  API_ENDPOINT: {
    signup: "auth/signup",
    login: "auth/login",
    logout: "auth/logout",
    resetPassword: "auth/updatePassword",
    sendResetPasswordEmail: "auth/sendResetPasswordEmail",
    resetPasswordUsingCode: "auth/resetPasswordUsingCode",
    updateEmail: "auth/updateEmail",
    user: "auth/user",
    allUsers: "auth/allUsers",
    allTeammatesUsers: "auth/allTeammatesUsers",
    getAccounts: "auth/getAccounts",
    switchAccount: "auth/switchAccount",
    account: "account",
    tariff: "tariff",
    duplicate: "duplicate",
    tariffVersion: "tariffVersion",
    lane: "lane",
    lanes: "lanes",
    routing: "routing",
    accessorial: "accessorial",
    createSection: "createSection",
    getAllSection: "getAllSection",
    fuelsurcharge: "fuelsurcharge",
    single: "single",
    syncWithPallet: "account/syncWithPallet",
    syncCarrierWithPallet: "account/syncCarrierWithPallet",
    condition: "condition",
    conditions: "conditions",
    quotes: "quotes",
    availableSections: "account/availableSections",
    availableAccessorials: "account/availableAccessorials",
    availableConditions: "account/availableConditions",
    lanePricingMethods: "account/lanePricingMethods",
    accessorialPricingMethods: "account/accessorialPricingMethods",
    fuelSurchargePricingMethods: "account/fuelSurchargePricingMethods",
    conditionsPricingMethods: "account/conditionsPricingMethods",
    customer: "customer",
    customers: "customers",
    customerGroup: "customerGroup",
    customerGroups: "customerGroups",
    assignCustomerGroup: "assignCustomerGroup",
    inviteCustomer: "inviteCustomer",
    eiaBaseRates: "account/eiaBaseRates",
    baseRate: "baseRate",
    baseRateLocations: baseRateId => `baseRate/${baseRateId}/locations`,
    classDiscount: "classDiscount",
    interlinePartner: "interlinePartner"
  },

  // API method
  API_METHOD: {
    post: "POST",
    get: "GET",
    put: "PUT",
    delete: "DELETE"
  }
};

export const masterAPI = (
  endPoint,
  method,
  queryParams,
  bodyPayload,
  changeHeaders
) => {
  let apiUrl = API.API_BASE_URL + API.API_VERSION + endPoint;
  let token = localStorage.getItem("id_token");

  if (queryParams) {
    apiUrl = apiUrl + "/" + queryParams;
  }

  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  if (changeHeaders) {
    headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token
    };
  }
  return axios(apiUrl, {
    method: method,
    headers: headers,
    data: bodyPayload
  })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response.status === 401) {
        // Changed errorKey to message as per the API response
        let errorKey = error.response.data.message;
        if (
          errorKey === "Auth.Token.Invalid" ||
          errorKey === "Auth.User.NotFound"
        ) {
          localStorage.removeItem("id_token");
          localStorage.removeItem("p_key");
          location.reload();
        }
      }
      return error.response;
    });
};
