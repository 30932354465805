<template>
  <div>
    <div v-for="(field, i) in dataTypeFieldList" :key="i">
      <div class="create-section-text mb-2 mt-4">
        Select a Data Type for this field
      </div>
      <div class="col-md-12 p-0">
        <div class="datatype-dropdown-inputs mb-2 dropdown-open-top">
          <ValidationProvider
            rules="required"
            name="Data type"
            v-slot="{ errors }"
          >
            <choices-single
              :id="'data_type_' + i"
              :options="dataTypeList"
              v-model="field.type"
              class="charge-unit-dropdown"
              @input="event => selectDataType(event, i)"
            >
              <option value="0">Select</option>
            </choices-single>
            <span class="validate-error">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="" v-if="field.pricingMethods">
          <div
            v-for="(item, index) in field.item"
            :key="index"
            class="row mb-2"
          >
            <div
              v-for="(subItem, subIndex) in field.pricingMethods.allowedValues"
              :key="subIndex"
              class="col-sm-3 col-md-12 col-lg-3"
            >
              <div v-if="subItem.type === 'select'" class="mt-2 mt-md-0">
                <ValidationProvider
                  rules="required"
                  :name="subItem.name"
                  v-slot="{ errors }"
                >
                  <label class="pricing-method-label">{{ subItem.name }}</label>
                  <choices-single
                    :id="subItem.name + i"
                    :options="subItem.values"
                    v-model="item[subItem.key]"
                    class="charge-unit-dropdown"
                  >
                    <option value="0">Select</option>
                  </choices-single>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div v-else class="form-group has-label mb-0">
                <label>{{ subItem.name }}</label>
                <div>
                  <ValidationProvider
                    rules="required"
                    :name="subItem.name"
                    v-slot="{ errors }"
                  >
                    <span
                      class="data-type-input"
                      :class="{
                        'currency-symbol-custom': subItem.name === 'Price'
                      }"
                      :data-symbol="
                        subItem.name === 'Price' ? currencySymbol : null
                      "
                    >
                      <input
                        :type="subItem.type"
                        step="any"
                        class="form-control"
                        :placeholder="subItem.placeholder"
                        v-model="item[subItem.key]"
                        v-on:keypress="positiveNumbersOnly"
                        @input="validateInput(i, index, subItem.key)"
                        @blur="addDecimal(i, index, subItem.key)"
                        v-on:wheel.prevent="subItem.type === 'number' ? $event.preventDefault() : null"
                        @keydown.up.prevent="subItem.type === 'number' ? $event.preventDefault() : null"
                        @keydown.down.prevent="subItem.type === 'number' ? $event.preventDefault() : null"
                      />
                    </span>
                    <span class="validate-error" style="display: block;">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div
              v-if="field.pricingMethods.allowMultipleValues"
              class="mt-lg-4"
            >
              <div class="mt-3 col-sm-3">
                <i
                  v-if="field.item.length > 1"
                  class="fa fa-trash field-icon"
                  @click="removeField(i, index)"
                >
                </i>
              </div>
            </div>
          </div>
          <div v-if="field.pricingMethods.allowMultipleValues" class="mt-3">
            <base-button @click="addField(i)">
              Add +
            </base-button>
            <input
              type="file"
              id="rate-upload"
              name="rates"
              @change="handleUpload"
              accept=".csv,.xlsx"
              hidden
            />
            <label
              for="rate-upload"
              class="rate-upload btn btn-default"
              icon="fa fa-upload"
            >
              Upload Rates
            </label>
            <base-button
              type="link"
              icon="fa fa-download"
              @click="downloadTemplate"
            >
              Download Template
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ChoicesSingle from "@/components/SingleSelect";
import { API, masterAPI } from "../../api/API";
import { getOptionDetailByName } from "@/helpers/utility";

export default {
  components: {
    ChoicesSingle
  },
  props: {
    dataTypeFieldList: {
      type: Array
    },
    dataTypeList: {
      type: Array
    },
    currencySymbol: {
      type: String
    }
  },
  data: function() {
    return {};
  },
  methods: {
    /**
     * Selects the data type for the dropdown at the specified index.
     * @param {string} e - The selected data type.
     * @param {number} i - The index of the data type in the dataTypeFieldList.
     */
    selectDataType(e, i) {
      let priceMethodDetail = getOptionDetailByName(this.dataTypeList, e);
      this.dataTypeFieldList[i].type = e;
      this.dataTypeFieldList[i].pricingMethods = priceMethodDetail;
      let unitPriceObj = {};
      this.dataTypeFieldList[i].item = [
        this.dataTypeFieldList[i].pricingMethods.allowedValues.reduce(
          (obj, allowedValue) => {
            obj[allowedValue.key] = "";
            return obj;
          },
          unitPriceObj
        )
      ];
    },
    /**
     * Allows only positive numbers and decimal point in the input field.
     * @param {Event} evt - The input event.
     * @returns {boolean} - Returns true if the input is valid, false otherwise.
     */
    positiveNumbersOnly(evt) {
      this.isDuplicate = true;
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    /**
     * Validates and formats the input value in the specified index of the dataTypeFieldList.
     * If the value contains a decimal point, it ensures that it has a maximum of 2 decimal places.
     * @param {number} mainIndex - The main index of the dataTypeFieldList.
     * @param {number} subIndex - The sub-index of the item within the dataTypeFieldList.
     */
    validateInput(mainIndex, subIndex, fieldKey) {
      let fixedValue = this.dataTypeFieldList[mainIndex].item[subIndex][
        fieldKey
      ];
      if (fixedValue.includes(".")) {
        const parts = fixedValue.split(".");
        if (parts[1] && parts[1].length > 2) {
          this.dataTypeFieldList[mainIndex].item[subIndex][fieldKey] = `${
            parts[0]
          }.${parts[1].substr(0, 2)}`;
        }
      }
    },

    removeField(mainIndex, subIndex) {
      this.$emit("remove-field", mainIndex, subIndex);
    },

    async addField(mainIndex) {
      this.$emit("add-field", mainIndex);
    },
    /**
     * Adds a decimal point to the value after the input field loses focus.
     * @param {number} mainIndex - The main index of the dataTypeFieldList.
     * @param {number} subIndex - The sub-index of the item within the dataTypeFieldList.
     */
    addDecimal(mainIndex, subIndex, fieldKey) {
      let fixedValue = this.dataTypeFieldList[mainIndex].item[subIndex][
        fieldKey
      ];
      if (fixedValue != "" && !fixedValue.includes(".")) {
        this.dataTypeFieldList[mainIndex].item[subIndex][fieldKey] =
          fixedValue + ".00";
      }
    },
    downloadTemplate() {
      window.open("/files/fsc_template.xlsx");
    },
    async handleUpload(e) {
      try {
        const { files } = e.target;
        if (files && files.length === 0) {
          return;
        }
        const formData = new FormData();
        formData.append("rates", files[0]);
        const response = await masterAPI(
          API.API_ENDPOINT.fuelsurcharge + "/parseRates",
          API.API_METHOD.post,
          null,
          formData,
          true
        );
        if (response.status >= 200 && response.status < 400) {
          this.$emit("update-items", response.data);
        } else {
          this.$toast.error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(error.message);
      } finally {
        e.target.value = null;
      }
    }
  }
};
</script>
<style>
.rate-upload {
  margin: 0;
}
</style>
