<template>
  <div>
    <v-app>
      <v-data-table :headers="headers" :items="tableData" class="elevation-1">
        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <base-button
              type="primary"
              class="btn-theme-EFEBEB left-border btn-br1"
              @click="editDuplicateView(item.id, true)"
            >
              <img src="../../assets/img/edit.png" />
            </base-button>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB right-border btn-br1"
              @click="editDuplicateView(item.id, false)"
            >
              <img src="../../assets/img/duplicate.png" />
            </base-button>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB table-action-button right-border"
              @click="showDeleteConfirmationModal(item.id)"
            >
              <img src="../../assets/img/trash.png" />
            </base-button>
          </div>
        </template>
      </v-data-table>
    </v-app>
    <confirmation-modal
      ref="confirmationModal"
      @delete="deleteinterlinePartner"
    />
  </div>
</template>

<script>
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";
import { API, masterAPI } from "@/api/API";
export default {
  bodyClass: "landing",
  components: {
    ConfirmationModal
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Contact Email", value: "contactEmail", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ],
      targetPartnerId: ""
    };
  },
  props: {
    tableData: {
      type: Array
    },
    sectionName: {
      type: String
    }
  },
  methods: {
    /**
      @param {string} interlinePartnerId The ID of the interlinePartner to be deleted
     */
    showDeleteConfirmationModal(interlinePartnerId) {
      this.targetinterlinePartnerId = interlinePartnerId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    async deleteinterlinePartner() {
      if (this.targetinterlinePartnerId) {
        const response = await masterAPI(
          API.API_ENDPOINT.interlinePartner,
          API.API_METHOD.delete,
          this.targetinterlinePartnerId
        );
        if (response.status == 200) {
          this.$toast.success("interlinePartner deleted successfully");
          this.$emit("remove-item", this.sectionName);
        }
      }
    },
    editDuplicateView(interlinePartnerId, isEdit) {
      const viewName = isEdit ? "edit" : "duplicate";
      this.$router.push({
        name: "editInterlinePartner",
        params: {
          view: viewName,
          id: interlinePartnerId
        }
      });
    }
  }
};
</script>

<style></style>
