<template>
    <div>
        <loader :isLoader="showLoader"></loader>
        <div v-if="selectedBaseUnit">
            <div class="create-section-text mb-2">
                Choose a Class
            </div>
           
            <div class="field-dropdown-main mb-4 price-method-dropdwon">
                <choices-single 
                    v-model="localSelectedClassUnits" 
                    :options="classUnitsListResult"
                    @input="event => handleSelectClassUnits(event)" 
                    :value="localSelectedClassUnits" 
                    id="selectedClassUnitsList"
                >
                    <option value="0">Select class</option>
                </choices-single>

                <span v-if="classRateInvalid" class="validate-error">
                    The Class is required
                </span>
            </div>
        </div>

        <div v-if="localSelectedClassUnits">
            <!-- View Class Button -->
            <div>
                <base-button
                    type="submit"
                    class="btn btn-default px-2 mt-2 mb-4"
                    v-on:click="fetchClassBreaks"
                >
                    <img src="../../assets/img/plus-active.svg" class=" pr-2" />
                    <span class="pr-3">
                        View Class Breaks and Apply Discounts 
                    </span>
                </base-button>
            </div>
            
            <!-- Input fields appears here after button click -->
            <div v-if="localIsViewClassBreaks">
                <div v-if="classPricingList.length === 0">
                    No pricing available.
                </div>

                <div v-else>
                    <div v-for="(item, index) in classPricingList" :key="index" class="row">
                        <div class="col-lg-3">
                            <base-input
                                type="number"
                                label="Per hundred lbs"
                                step="any"
                                class="mb-0"
                                placeholder="0"
                                :disabled="true"
                                v-model="item.perHundredLbs"
                            >
                            </base-input>
                        </div>

                        <div class="form-group has-label col-lg-4">
                            <label>Price</label>
                            <div>
                                <span
                                    class="data-type-input currency-symbol-custom"
                                    :data-symbol="currencySymbol"
                                    :class="{ 'currency-symbol-dollarSign': currencySymbol === '$' }"
                                >
                                    <input
                                        type="number"
                                        step="any"
                                        class="mb-0 form-control"
                                        placeholder="0"
                                        :disabled="true"
                                        v-model="item.price"
                                        @wheel.prevent
                                        @keydown.up.prevent
                                        @keydown.down.prevent
                                    />
                                </span>
                        
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <base-input
                                type="number"
                                label="Discount"
                                step="any"
                                class="mb-0"
                                placeholder="%"
                                v-model="item.discount"
                            >
                            </base-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { classDataBaseLane } from "@/helpers/utility";
import ChoicesSingle from "@/components/SingleSelect";
import { API, masterAPI } from "@/api/API";
import Loader from "@/views/common/Loader.vue";

export default {
    bodyClass: "landing",
    components: {
        ChoicesSingle,
        Loader
    },
    props: {
        selectedBaseUnit: {
            type: String
        },
        selectedClassUnits: {
            type: String
        },
        isViewClassBreaks: {
            type: Boolean
        },
        currencySymbol: {
            type: String
        },
        classRateInvalid: {
            type: Boolean
        },
        origin: {
            type: String
        },
        destination: {
            type: String
        },
        baseRateId:{
            type: Number
        },
        borderSelected: {
            type: String
        }
    },
    data() {
        return {
            localIsViewClassBreaks: this.isViewClassBreaks, // Create local data based on prop
            localSelectedClassUnits: this.selectedClassUnits,
            classPricingList: [],
            showLoader: false,
        };
    },
    watch: {
        // Watch the prop and update local data if the prop changes
        isViewClassBreaks(newVal) {
            this.localIsViewClassBreaks = newVal;
        },
        // Watch the selectedClassUnits and update the localSelectedClassUnits with the new value
        selectedClassUnits: {
            handler(newVal, oldVal) {
                this.localSelectedClassUnits = newVal;
                if (oldVal !== null && oldVal !== '' && newVal !== null && newVal !== '') {
                    // If newVal is different from oldVal, set localIsViewClassBreaks to false
                    if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                        this.localIsViewClassBreaks = false;
                        this.classPricingList = [];
                        this.$emit("update-class-pricing", this.classPricingList);
                    }
                }
                // If localSelectedClassUnits is an empty string, update the UI text
                this.$nextTick(() => {
                    if (this.localSelectedClassUnits === '') {
                        const selectedItem = document.querySelector("#selectedClassUnitsList .choices__item");
                        if (selectedItem) {
                            selectedItem.innerText = "Select";
                        }
                    }
                });
            },
        deep: true 
        },
    },
    methods: {
        /**
         * Fetches class rate data from the API based on selected origin, destination, and baseRate.
         */
        async fetchClassBreaks() {
            this.localIsViewClassBreaks = true; 
            this.$emit('update:isViewClassBreaks', true); 

            if(this.borderSelected == 'crossBorder') {
                this.classPricingList = [
                    { perHundredLbs: null, price: null, discount: null } // Initialize with one set
                ]
            } else {
                try {
                    // Check if both origin and destination are selected
                    if (!this.origin || !this.destination) {
                        this.$toast.error('Please select both origin and destination before fetching rates.');
                        return; // Prevent further execution if either is not selected
                    }
                    
                    this.showLoader = true;
                    this.classPricingList = [];

                    let endPoint = `${API.API_ENDPOINT.baseRate}/${this.baseRateId}/rates?origin=${this.origin}&destination=${this.destination}`;

                    let response = await masterAPI(
                        endPoint,
                        API.API_METHOD.get
                    );

                    this.showLoader = false;

                    if (response.status === 200) {
                        const rates = response.data.rates;
                        const selectedClass = this.localSelectedClassUnits;
                        const classNumber = selectedClass.replace("Class ", "");
                        const matchedRate = rates.find(rate => rate.class === classNumber);

                        if (matchedRate) {
                            this.classPricingList = Object.keys(matchedRate)
                            .filter(key => key !== "class") 
                            .map(key => ({
                                perHundredLbs: key,
                                price: matchedRate[key],
                                discount: null
                            }));

                            // Emit the updated classPricingList to parent component
                            this.$emit("update-class-pricing", this.classPricingList);
                        }
                    }
                } catch (error) {
                    console.error("API Error:", error);
                }
            }
        },
        /**
         * Emits the selected class units to the parent and hides class breaks view
         */
        handleSelectClassUnits() {
            this.$emit('select-class-units', this.localSelectedClassUnits)
            this.$emit('update:isViewClassBreaks', false); 
            this.localIsViewClassBreaks = false;
        },
        /**
         * Fetch and set lane data from localStorage based on laneId.
        */
        fetchLaneData() {
            if (this.laneId) {
                // Retrieve lane list from localStorage and parse it
                const laneList = JSON.parse(localStorage.getItem("laneList"));

                // Find the lane matching the provided laneId
                let lane = laneList.map(item => {
                    if (item.id == this.laneId) {
                        return item;
                    }
                }).filter(function(element) {
                    return element !== undefined;
                });

                setTimeout(() => {
                    document.querySelector(
                    "#selectedClassUnitsList .choices__item"
                    ).innerText =  lane[0].metadata.notCrossBorderClassPricing.class;
                }, 30);

                // Set selected class units and class pricing list
                this.localSelectedClassUnits = lane[0].metadata?.notCrossBorderClassPricing?.class;
                this.localIsViewClassBreaks = true;
                this.classPricingList =  lane[0].metadata?.notCrossBorderClassPricing?.value;
            
                // Emit updates to parent component
                this.$emit('select-class-units', this.localSelectedClassUnits);
                this.$emit("update-class-pricing", this.classPricingList);
            }
        }
    },
    computed: {
        classUnitsListResult() {
            return classDataBaseLane.map(option => ({
                id: option.key,
                text: option.name
            }));
        },
  
        // Computed property for laneId
        laneId() {
            return +this.$route.params.id;
        },
    },
    mounted() {
        // fetch the lane data and set the Class value
        this.fetchLaneData();
    }
}
</script>