<template>
  <div class="wrapper tariff-app-container-height">
    <ValidationObserver v-slot="{ validate }" ref="formValidator">
      <form
        @submit.prevent="validate().then(saveInterlinePartner(false))"
        class="needs-validation"
        id="add-routing"
      >
        <div class="row">
          <div class="col-md-12 col-lg-5">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <base-input type="text" label="Name" name="Name" v-model="name">
              </base-input>
              <span v-if="errors.length" class="validate-error">
                Name is required
              </span>
            </ValidationProvider>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-5">
            <ValidationProvider
              contactEmail="contactEmail"
              rules="required"
              v-slot="{ errors }"
            >
              <base-input
                type="text"
                label="Contact Email"
                contactEmail="contactEmail"
                v-model="contactEmail"
              >
              </base-input>
              <span v-if="errors.length" class="validate-error">
                contactEmail is required
              </span>
            </ValidationProvider>
          </div>
        </div>

        <button
          :disabled="isFormInvalid"
          type="submit"
          class="btn mt-3 btn-default btn-disable"
        >
          Save
        </button>

        <div>
          <button
            :disabled="isFormInvalid"
            type="button"
            class="btn mt-3 btn-default btn-disable"
            @click="saveInterlinePartner(true)"
          >
            Save and Exit
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { API, masterAPI } from "../../api/API";
import { sectionList } from "../../helpers/utility";
export default {
  bodyClass: "landing",
  data() {
    return {
      name: "",
      contactEmail: "",
      isFormInvalid: false
    };
  },
  props: {
    moduleView: {
      type: String
    },
    isCreate: {
      type: Boolean
    }
  },
  methods: {
    async checkFormValidity() {
      const isValid = await this.$refs.formValidator.validate();
      this.isFormInvalid = !isValid;
    },
    async saveInterlinePartner(isSaveExit) {
      if (this.isFormInvalid) {
        return;
      }
      const formType = this.$route.params.view || "create";
      const methods = {
        edit: API.API_METHOD.put,
        duplicate: API.API_METHOD.post,
        create: API.API_METHOD.post
      };
      const id = this.$route.params.id;
      const bodyPayload = {
        name: this.name,
        contactEmail: this.contactEmail
      };
      const response = await masterAPI(
        API.API_ENDPOINT.interlinePartner,
        methods[formType],
        formType === "edit" ? id : null,
        JSON.stringify(bodyPayload)
      );
      if (response.status >= 200 && response.status < 400) {
        this.$toast.success("Interline Partner saved successfully");
        if (isSaveExit) {
          this.$router.push({
            name: "viewallsection",
            params: { preselected: sectionList.interlinePartner }
          });
        }
      } else {
        this.$toast.error(response.data.message);
      }
    },
    async getInterlinePartner() {
      const id = this.$route.params.id;
      if (id) {
        const response = await masterAPI(
          API.API_ENDPOINT.interlinePartner,
          API.API_METHOD.get,
          id
        );
        if (response.status >= 200 && response.status < 400) {
          const { data } = response;
          this.name = data.name;
          this.contactEmail = data.contactEmail;
        } else {
          this.$toast.error(response.data.message);
        }
        return true;
      } else {
        return false;
      }
    }
  },
  beforeMount() {
    this.getInterlinePartner().then(isEdit => {
      if (isEdit) {
        this.checkFormValidity();
      }
    });
  }
};
</script>

<style></style>
