<template>
  <div>
    <v-app id="inspire">
      <v-data-table
        :headers="dynamicHeaders"
        :items="tableData"
        :hide-default-footer="true"
        class="elevation-1"
        :items-per-page="tableData.length"
        :mobile-breakpoint="0"
      >
        <!-- Custom header slot for the "editing" column -->
        <template v-slot:header.editing v-if="isEditTariff">
          <editing-header-tariff
            :selectAll="selectAll"
            :tariffValue="headerTariffValue"
            @update-header-price-mode="handleUpdateHeaderPriceMode"
            :priceMode="priceMode"
            @update-select-all="handleSelectAll"
            @update-tariff-value="handleHeaderTariffValue"
            :uniqueId="uniqueId"
            :listName="listName"
            :tableData="tableData"
          />
        </template>

        <!-- Slot for dynamically rendering editing column -->
        <template v-slot:item.editing="{ item }" v-if="isEditTariff">
          <editing-column-tariff
            :item="item"
            :tariffValue="headerTariffValue"
            @row-selected="handleRowSelection"
            @update-price-mode="handleUpdatePriceMode"
            @update-tariff-value="handleUpdateTariffValue"
            :selectAll="selectAll"
            :listName="listName"
            :key="item.id"
          />
        </template>

        <template v-slot:item.origin="{ item }">
          <origin-destination-dropdown
            :name="item.origin"
            :options="item.subCity.origin"
          />
        </template>

        <template v-slot:item.destination="{ item }">
          <origin-destination-dropdown
            :name="item.destination"
            :options="item.subCity.destination"
          />
        </template>

        <template v-slot:item.pricingMethod="{ item }">
          {{ item.pricingMethodTypeName }}
        </template>

        <template v-slot:item.value="{ item }">
          <price-value-dropdown :item="item" />
        </template>

        <template v-slot:item.updatedAt="{ item }">
          <div class="mb-1 mt-1">
            {{ item.updatedAt }}
          </div>
        </template>

        <template v-slot:item.action="{ item }">
          <div v-if="isTariff" @click="removeItem(item)" class="text-center">
            <i v-if="isParentView" class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div v-else>
            <lane-action-button :item="item" @remove-item="removeItem" />
          </div>
        </template>
      </v-data-table>
    </v-app>

    <!-- Modal component for displaying the selected updated date. -->
    <view-updated-date-modal
      ref="viewUpdatedAtModal"
      :selectedItem="selectedUpdatedAt"
    />
  </div>
</template>

<script>
import OriginDestinationDropdown from "@/views/common/OriginDestinationDropdown.vue";
import PriceValueDropdown from "@/views/common/PriceValueDropdown.vue";
import LaneActionButton from "@/views/common/LaneActionButton.vue";
import ViewUpdatedDateModal from "@/views/common/ViewUpdatedDateModal.vue";
import EditingColumnTariff from "@/views/tariff/EditingColumnTariff.vue";
import EditingHeaderTariff from "@/views/tariff/EditingHeaderTariff.vue";

export default {
  bodyClass: "landing",
  components: {
    OriginDestinationDropdown,
    PriceValueDropdown,
    LaneActionButton,
    ViewUpdatedDateModal,
    EditingColumnTariff,
    EditingHeaderTariff
  },
  data: function() {
    return {
      // Component's data properties
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Origin", value: "origin", sortable: false },
        { text: "Destination", value: "destination", sortable: false },
        { text: "Pricing Method", value: "pricingMethod", sortable: false },
        { text: "Value", value: "value", sortable: false },
        {
          text: "Interline Partner",
          value: "interlinePartner",
          sortable: false
        },
        {
          text: "Interline Revenue Share",
          value: "interlineRevenueShare",
          sortable: false
        },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Updated On", value: "updatedAt", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ],
      selectedUpdatedAt: "",
      // selectAll: false,
      headerTariffValue: null,
      localSelectAll: this.selectAll
    };
  },
  watch: {
    selectAll(newVal) {
      this.localSelectAll = newVal;
    }
  },
  props: {
    // Component props
    tableData: {
      type: Array
    },
    isTariff: {
      type: Boolean
    },
    isParentView: {
      type: Boolean
    },
    isEditTariff: {
      type: Boolean
    },
    selectAll: {
      type: Boolean
    },
    uniqueId: {
      type: String
    },
    listName: {
      type: String
    },
    priceMode: {
      type: String
    }
  },
  /**
   * Dynamically generates the headers for the table based on the edit mode.
   */
  computed: {
    dynamicHeaders() {
      if (this.isEditTariff) {
        return [
          { text: "", value: "editing", sortable: false, width: "222px" },
          ...this.headers
        ];
      }
      return this.headers;
    }
  },
  methods: {
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    async removeItem(item) {
      // Emit an event to notify parent component to remove the item
      this.$emit("remove-item", item);
    },
    /**
     * Opens a modal to view updated date of a selected item.
     *
     * @param {Object} item - The selected item to view updated date for.
     */
    viewUpdatedAt(item) {
      this.selectedUpdatedAt = item.updatedAt;
      this.$refs.viewUpdatedAtModal.openModal();
    },
    /**
     * Handles the "select all" action.
     *
     * @param {boolean} value - Whether to check (true) or uncheck (false) all items.
     */
    handleSelectAll(value) {
      this.localSelectAll = value;
      this.$emit("update-select-all", value);
    },
    /**
     * Handles changes to the header tariff value and updates all rows accordingly.
     *
     * @param {number} value - The new tariff value to set for all rows.
     */
    handleHeaderTariffValue(value) {
      this.headerTariffValue = value;

      // Update all row values when header input changes
      this.tableData.forEach(item => {
        item.tariffValue = value;
      });
      this.$emit("update-tariff-value", { id: this.uniqueId, value });
    },
    /**
     * Handles the selection of a row and logs the selected row ID.
     *
     * @param {string} selectedItem - The selected row.
     */
    handleRowSelection(selectedItem) {
      this.$emit("row-selected", selectedItem);
    },
    /**
     * Updates the price mode for a specific lane based on its ID
     *
     * @param {string|number} laneId - The ID of the lane to update.
     * @param {string} newPriceMode - The new price mode.
     */
    handleUpdatePriceMode(laneId, newPriceMode) {
      this.$emit("update-price-mode", laneId, newPriceMode, this.listName);
    },
    /**
     * Updates the header price mode and emits an event.
     *
     * @param {string} newPriceMode - The updated price mode for the header.
     */
    handleUpdateHeaderPriceMode(newPriceMode) {
      this.$emit("update-header-price-mode", newPriceMode);
    },
    /**
     * Emits the updated tariff value to the parent component.
     *
     * @param {Object} payload - The payload containing the updated tariff value.
     */
    handleUpdateTariffValue(payload) {
      // Re-emit the event to the parent
      this.$emit("update-tariff-value", payload);
    }
  }
};
</script>

<style></style>
