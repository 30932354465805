<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-lg-5 text-transform-capitalize">
        <label>{{ locationType }}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <div class="form-group has-label">
          <vue-google-autocomplete
            classname="form-control mb-1"
            placeholder="City - Postal code"
            :id="locationType + '_city'"
            :ref="locationType + '_city'"
            v-model="city"
            types="(regions)"
            :country="country"
            v-on:placechanged="setCity"
            v-on:change="clearCity"
          >
          </vue-google-autocomplete>
          <span v-if="isCityInvalid" class="validate-error"
            >The {{ locationType }} field is required</span
          >
        </div>
        <div class="mb-3" v-if="!isAddingSubCity">
          <a class="btn btn-link a-btn" @click="addSubCity">
            Add Sub-city +
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <button
          v-if="routingStatus === 'none'"
          @click="toggleOriginRoutingList(true)"
          type="button"
          class="btn btn-default"
        >
          <i class="fa fa-plus-circle" />
          Select Routing
        </button>
        <button
          v-if="routingStatus === 'added'"
          @click="toggleOriginRoutingList(true)"
          type="button"
          class="btn btn-default"
        >
          Change Routing
        </button>
        <choices-single
          v-if="routingStatus === 'adding'"
          :id="locationType + '-routing'"
          :options="routingMenuOptions"
          @input="(_, index) => populateRoutingChoice(index)"
        >
          <option value="0">Select Routing</option>
        </choices-single>
      </div>
    </div>
    <div v-if="isAddingSubCity" class="row">
      <div class="col-md-2"></div>
      <div class="col-md-12 col-lg-6">
        <div v-for="(item, index) in subCities" :key="index" class="row mb-2">
          <div class="col-10 col-md-10 autocomplete-pr has-label">
            <vue-google-autocomplete
              :disabled="routingStatus !== 'none'"
              :ref="locationType + '_sub_city_' + index"
              classname="form-control mb-1"
              placeholder="City - Postal code"
              :id="locationType + '_sub_city_' + index"
              v-model="subCities[index]"
              types="(regions)"
              :country="country"
              v-on:placechanged="(_, placeData) => setSubCity(placeData, index)"
            >
            </vue-google-autocomplete>
          </div>
          <div class="col-2 col-md-2" v-if="routingStatus === 'none'">
            <i class="fa fa-trash delete-icon" @click="removeSubCity(index)">
            </i>
          </div>
        </div>
        <div class="mb-3 mt-2" v-if="routingStatus !== 'added'">
          <a class="btn btn-link a-btn" @click="addAdditionalSubCity">
            Add additional sub-city +
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ChoicesSingle from "@/components/SingleSelect";
import "../../types.js";

export default {
  components: {
    VueGoogleAutocomplete,
    ChoicesSingle
  },
  data() {
    return {
      country: ["us", "ca"],
      /** @type {'none' | 'adding' | 'added'} */
      routingStatus: "none",
      isAddingSubCity: !!this.subCities,
      isCityInvalid: false,
      city: ""
    };
  },
  props: {
    locationType: {
      validator: function(value) {
        return ["origin", "destination"].includes(value);
      },
      required: true
    },
    existingCity: String,
    subCities: {
      type: Array,
      required: true
    },
    /** @type {Routing[]} */
    routings: {
      default: () => [],
      type: Array
    },
    /** @type {Routing | null} */
    defaultRouting: Object
  },
  watch: {
    existingCity(newVal) {
      if (this.city !== newVal) {
        this.city = newVal;
      }
    },
    /** @param {Routing} routing */
    defaultRouting(routing) {
      if (routing) {
        this.routingStatus = "added";
        this.city = routing.parentCity;
        this.$emit(
          "update:subcities",
          routing.subCities.map(({ city }) => city)
        );
      }
    }
  },
  computed: {
    routingMenuOptions: function() {
      function generateListOptions(routing, index) {
        return {
          text: routing.name,
          key: routing.name,
          id: index
        };
      }
      if (this.city) {
        // User has chosen a parent city, so filter available routings based on that city
        return this.routings
          .filter(routing => routing.parentCity === this.city)
          .map(generateListOptions);
      }
      return this.routings.map(generateListOptions);
    },
    filteredRoutings: function() {
      if (this.city !== "") {
        return this.routings.filter(
          routing => routing.parentCity === this.city
        );
      }
      return this.routings;
    }
  },
  methods: {
    /**
     * v-on:placechanged does not trigger when emptying the input, and v-on:change does not verify the city,
     * so we're just adding a change listener for an empty input to clear the chosen city.
     */
    clearCity(input) {
      if (input === "") {
        this.city = "";
        this.clearRouting();
      }
    },
    clearRouting() {
      this.routingStatus = "none";
      const updatedSubCities = [...this.subCities];
      updatedSubCities.splice(0);
      this.$emit("update:subcities", updatedSubCities);
      this.$emit("routing-updated", { [this.locationType]: null });
    },
    setCity(_, place) {
      this.city = place.formatted_address;
      this.clearRouting();
    },
    focusSubCity() {
      this.$nextTick(() => {
        const lastIndex = this.subCities.length - 1;
        document
          .getElementById(`${this.locationType}_sub_city_${lastIndex}`)
          .focus();
      });
    },
    /** Add the first empty subcity input */
    addSubCity() {
      if (this.city === "") {
        this.isCityInvalid = true;
      } else {
        this.isCityInvalid = false;
        this.isAddingSubCity = true;
        const updatedSubCities = [...this.subCities, ""];
        this.$emit("update:subcities", updatedSubCities);
        this.focusSubCity();
      }
    },
    setSubCity(place, index) {
      const updatedSubCities = [...this.subCities];
      updatedSubCities[index] = place.formatted_address;
      this.$emit("update:subcities", updatedSubCities);
    },
    /** Add additional empty subcity inputs */
    addAdditionalSubCity() {
      this.routingStatus = "none";
      const updatedSubCities = [...this.subCities, ""];
      this.$emit("update:subcities", updatedSubCities);
      this.focusSubCity();
    },
    removeSubCity(index) {
      const updatedSubCities = [...this.subCities];
      updatedSubCities.splice(index, 1);
      this.$emit("update:subcities", updatedSubCities);
      this.updateSubcitiesInputs();
    },
    toggleOriginRoutingList(show) {
      if (this.routingStatus === "added") {
        // User is changing the chosen routing, so clear the current one
        this.clearRouting();
      }
      if (show) {
        this.routingStatus = "adding";
      }
    },
    /** Updates the values for the Google Autocomplete inputs of subCities. */
    updateSubcitiesInputs() {
      this.$nextTick(() => {
        this.subCities.forEach((item, index) => {
          this.$refs[`${this.locationType}_sub_city_${index}`][0].update(item);
        });
      });
    },
    /**
     * Populate the origin or destination and its subcities with a routing,
     * and store the chosen routing's ID.
     * @param {number} index The chosen routing index.
     */
    populateRoutingChoice(index) {
      /** @type {Routing} */
      const routing = this.filteredRoutings[index];
      this.city = routing.parentCity;
      this.$refs[`${this.locationType}_city`].update(this.city);
      this.isCityInvalid = false;
      this.isAddingSubCity = true;
      const newSubCities = routing.subCities.map(({ city }) => city);
      this.$emit("update:subcities", newSubCities);
      this.routingStatus = "added";
      this.updateSubcitiesInputs();
      this.$emit("routing-updated", { [this.locationType]: routing });
    }
  }
};
</script>
