<template>
  <div class="d-flex profile-container">
    <choices-single
      v-model="selectedCurrencyUser"
      id="currencyListDropdown"
      :options="currencyList"
      class="currency-dropdown-header"
      @input="event => changeCurrency(event)"
    >
      <option value="0">Select Currency</option>
    </choices-single>

    <img class="mr-3" src="../assets/img/notification.svg" alt="logo" />

    <el-dropdown trigger="click">
      <img class="c-p" src="../assets/img/user.svg" alt="logo" />
      <el-dropdown-menu slot="dropdown" class="l-override">
        <div class="dropdown-item userprofile-text">
          <b>{{ accountName }}</b>
        </div>
        <div v-if="externalId" class="dropdown-item font-weight-light mt-2">
          <b class="userprofile-text">{{ externalId }}</b>
        </div>
        <br />
        <div class="dropdown-item logout-icon" v-on:click="switchTenant">
          Switch Tenant
        </div>
        <br />
        <span class="dropdown-item logout-icon" v-on:click="logout">
          Logout
        </span>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import { API, masterAPI } from "@/api/API";
import ChoicesSingle from "@/components/SingleSelect";
import { mapGetters } from "vuex";
import { currencyList } from "@/helpers/utility";

export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ChoicesSingle
  },
  data: function() {
    return {
      currencyList: currencyList
    };
  },
  computed: {
    ...mapGetters(["currencyType"]),
    accountName() {
      return this.$store.state.account?.name || "";
    },
    externalId() {
      return this.$store.state.account?.metadata?.externalId || "";
    },
    selectedCurrencyUser: {
      get() {
        return this.currencyType;
      },
      set(value) {
        this.changeCurrency(value);
      }
    }
  },
  methods: {
    switchTenant() {
      this.$router.push({
        name: "tenant"
      });
    },
    logout: async function() {
      let response = await masterAPI(
        API.API_ENDPOINT.logout,
        API.API_METHOD.post,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.$toast.success("Logout successful", { timeout: 100 });
        localStorage.removeItem("id_token");
        localStorage.removeItem("p_key");
        localStorage.removeItem("accountId");
        this.$router.push({ path: "/", name: "register" }).catch(() => {});
      }
    },
    changeCurrency(value) {
      this.$store.dispatch("updateCurrencyType", value);
    }
  }
};
</script>

<style>
.l-override {
  left: calc(100% - 180px) !important;
  min-width: 180px !important;
}
.userprofile-text {
  word-break: break-all;
  max-width: 160px;
  display: inline-block;
}
</style>
