<template>
  <div>
    <div v-if="item.pricingMethodTypeName == 'Fixed'">
      {{ item.value != "" ? "$" + item.value : "" }}
    </div>
    <div v-else>
      <div v-if="pricingValues.length" class="font-bold mb-1 mt-1">
        <el-dropdown trigger="click">
          <a class="btn-link view-all-link-btn mb-2">
            View all
          </a>
          <el-dropdown-menu slot="dropdown">
            <div class="row pricing-value-header mb-2">
              <div 
                v-for="(key, index) in allowedKeys" 
                :key="index" 
                class="col-6 font-bold text-transform-capitalize price-keys"
              >
                {{ key }}
              </div>
            </div>
      
            <div v-for="(pricingValue, i) in pricingValues" :key="i" class="row">
              <div 
                v-for="(key, index) in allowedKeys" 
                :key="index" 
                class="col-6 cmb-3"
              >
                <span v-if="key === 'price'">
                  {{ pricingValue[key] !== "" ? "$" + pricingValue[key] : "" }}
                </span>
                <span v-else>
                  {{ pricingValue[key] }}
                </span>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import {  pricingMethodType } from "../../helpers/utility";

export default {
  name: "",
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    // Retrieves the appropriate pricing values based on the pricing method type
    pricingValues() {
      return this.item.pricingMethodTypeName === pricingMethodType.class
        ? this.item.metadata?.notCrossBorderClassPricing?.value || []
        : this.item.value || [];
    },
    // Retrieves the allowed keys for pricing, filtering out 'discount' and ensuring 'perHundredLbs' appears before 'price'
    allowedKeys() {
      if (this.item.pricingMethodTypeName === pricingMethodType.class) {
        // Get all keys from the first item in the value array
        const keys = Object.keys(this.item.metadata?.notCrossBorderClassPricing?.value?.[0] || {});
        
        // Filter out the 'discount' key
        const filteredKeys = keys.filter(key => key !== 'discount');
        
        // Swap the 'perHundredLbs' and 'price' keys
        const indexPerHundredLbs = filteredKeys.indexOf('perHundredLbs');
        const indexPrice = filteredKeys.indexOf('price');
        
        if (indexPerHundredLbs > -1 && indexPrice > -1) {
          // Swap the keys
          [filteredKeys[indexPerHundredLbs], filteredKeys[indexPrice]] = [filteredKeys[indexPrice], filteredKeys[indexPerHundredLbs]];
        }
        return filteredKeys;
      } else {
        // Return allowed values for other cases
        return this.item.pricingMethodItem.allowedValues.map(av => av.key);
      }
    }
  }
};
</script>
<style>
  .price-keys {
    word-break: break-word;
  }
</style>
