<template>
  <div class="wrapper tariff-app-container-height">
    <app-header activeMenuName="edit-interline-partner" />
    <div class="col-md-7 container-right-border">
      <div class="custom-padding">
        <div class="mb-3">
          <span class="back-text-btn" @click="backView">
            Back
          </span>
        </div>
        <interline-partner-form />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/layout/AppHeader.vue";
import InterlinePartnerForm from "@/views/interlinePartner/InterlinePartnerForm.vue";
import { sectionList } from "@/helpers/utility";
export default {
  bodyClass: "landing",
  components: {
    AppHeader,
    InterlinePartnerForm
  },
  methods: {
    backView() {
      this.$router.push({
        name: "viewallsection",
        params: { preselected: sectionList.interlinePartner }
      });
    }
  }
};
</script>
